import moment from 'moment'

export const DAYS = {
    MONDAY: 'Mon',
    TUESDAY: 'Tue',
    WEDNESDAY: 'Wed',
    THURSDAY: 'Thu',
    FRIDAY: 'Fri',
    SATURDAY: 'Sat',
    SUNDAY: 'Sun',
}

export const DAYS_ARRAY = Object.values(DAYS)

export const HOUR_PERIOD = {
    AM: 'AM',
    PM: 'PM',
}

export const LOCAL_OFFSET = moment.parseZone(new Date()).utcOffset() / 60

export const SCHEDULE_NAMES_ATTRIBUTES = 'schedulesNames'

import Vue from "vue";
import { MeterGreen, Gateway, Device } from "../../api";

const initialState = () => {
 return {
    meters: [],
    gateways: [],
    mainGateway: null,
  }
}

export const gateways = {
  namespaced: true,
  state: initialState(),
  getters: {
    getAllMeters(state) {
      return state.meters;
    },
  },
  actions: {
    async fetchGateways({ commit }) {
      try {
        const gateways = JSON.parse(localStorage.getItem("gateways"));
        if (!gateways || gateways.length === 0) {
          const response = await Gateway.get();
          const formattedGateways = response.data.map((gateway) => {
            const { name_eui: id, name, main, key } = gateway;
            return { name, main, key, id };
          });
          commit("SET_GATEWAYS", formattedGateways);
          return;
        }
        commit("SET_GATEWAYS", gateways);
      } catch (error) {
        console.log(error);
      }
    },
    async addGateway({ commit }, data) {
      try {
        const { key, id, name } = data;
        await Gateway.post(key, id, name);
        commit("ADD_GATEWAY", data);
      } catch (error) {
        console.error(error.response.data.data);
        throw error;
      }
    },
    async assignGateway({ commit, state }, id) {
      try {
        const mainAssigned = state.gateways.some((gateway) => gateway.main);
        mainAssigned && (await Gateway.deleteMainGateway());
        await Gateway.assign(id);
        commit("ASSIGN_GATEWAY", id);
      } catch (error) {
        console.error(error.response.data.data);
        throw error;
      }
    },
    async deleteGateway({ commit }, id) {
      try {
        await Gateway.deletion(id);
        commit("DELETE_GATEWAY", id);
      } catch (error) {
        console.error(error.response.data.error);
        throw error;
      }
    },
    async fetchMeters({ commit }) {
      try {
        const response = await Device.get();
        const meters = response.data
          .filter((device) => device.class === "Meter")
          .map((meter) => ({
            ...meter,
            id: meter.functions.Basic.assetId,
            name: meter.functions.Basic.displayName,
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        commit("SET_METERS", meters);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMeter({ }, id) {
      try {
        const response = await Device.getNode(id);
        const meter = response.data;
        meter.id = meter.functions.Basic.assetId;
        meter.name = meter.functions.Basic.displayName;
        return meter;
      } catch (error) {
        console.error(error);
      }
    },
    async addMeters({ commit }, meters) {
      try {
        const newMeters = [];
        for (let index = 0; index < meters.length; index++) {
          const { id, name } = meters[index];
          const response = await MeterGreen.addDevice(id, name);
          const meter = response.data;
          meter.id = meter.functions.Basic.assetId;
          meter.name = meter.functions.Basic.displayName;
          newMeters.push(meter);
        }
        commit("ADD_METERS", newMeters);
      } catch (error) {
        console.error(error.response.data.message);
        throw error.response.data;
      }
    },
    async editMeter({ commit }, meter) {
      try {
        const { id, name } = meter;
        await MeterGreen.updateDisplayName(id, name);
        const response = await Device.getNode(id);
        const updatedMeter = response.data;
        updatedMeter.name = updatedMeter.functions.Basic.displayName;
        updatedMeter.id = updatedMeter.functions.Basic.assetId;
        commit("EDIT_METER", updatedMeter);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async deleteMeters({ commit }, meter) {
      try {
        await MeterGreen.deletion(meter);
        commit("DELETE_METER", meter);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    SET_GATEWAYS(state, gateways) {
      localStorage.setItem("gateways", JSON.stringify(gateways));
      state.gateways = JSON.parse(localStorage.getItem("gateways"));
      state.gateways.sort((a, b) => b.main - a.main);
      const foundMainGateway = [...state.gateways].find(
        (gateway) => gateway.main === true
      );
      if (!foundMainGateway) return;
      const { id } = foundMainGateway;
      state.mainGateway = id;
    },
    ADD_GATEWAY(state, data) {
      state.gateways.push(data);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
    ASSIGN_GATEWAY(state, id) {
      state.gateways.forEach((gateway) => {
        gateway.main = gateway.id === id;
      });
      state.gateways.sort((a, b) => b.main - a.main);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
    DELETE_GATEWAY(state, id) {
      state.gateways = state.gateways.filter((gateway) => gateway.id !== id);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
    SET_METERS(state, meters) {
      state.meters = meters;
      localStorage.setItem("metersv2", JSON.stringify(meters));
    },
    ADD_METERS(state, meters) {
      state.meters = [...state.meters, ...meters];
      localStorage.setItem("metersv2", JSON.stringify(state.meters));
    },
    EDIT_METER(state, updatedMeter) {
      const index = state.meters.findIndex(
        (meter) => meter.id === updatedMeter.id
      );
      Vue.set(state.meters, index, updatedMeter);
      localStorage.setItem("metersv2", JSON.stringify(state.meters));
    },
    DELETE_METER(state, meterId) {
      state.meters = state.meters.filter((meter) => meter.id !== meterId);
      localStorage.setItem("metersv2", JSON.stringify(state.meters));
    },
    RESET(state) {
      Object.assign(state, initialState());
    }
  },
};

export default async function getBase64Image(url) {
    const data = await fetch(url)
    const blob = await data.blob()
    const reader = new FileReader()

    const base64data = await new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })

    return base64data
}

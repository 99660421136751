import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { auth } from './plugins/firebase'
import 'firebase/auth'
import VueApexCharts from 'vue-apexcharts'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)
Vue.use(VueMeta)

let app

auth.onAuthStateChanged(async () => {
    try {
        if (!app) {
            app = new Vue({
                i18n,
                store,
                router,
                vuetify,
                render: (h) => h(App),
            }).$mount('#app')
        }

        store.dispatch('commands/initCommandsLogsModule', null, { root: true })

        if (!auth.currentUser) {
            store.dispatch('authentication/logout', null, { root: true })
            store.dispatch('project/unsetProject', null, { root: true })
            store.dispatch('user/cleanUserData', false, { root: true })
            store.dispatch('messages/clean_messages', null, { root: true })
            store.dispatch('app/set_drawer_visible', false, { root: true })
            return
        }

        await store.dispatch('user/setUserData', null, { root: true })
        await store.dispatch('project/loadSavedProject', null, { root: true })

        if (!store.state.project.id) router.push({ name: 'selectProject' })
        store.dispatch('app/set_drawer_visible', true, { root: true })
    } catch (error) {
        store.dispatch('authentication/logout', false, { root: true })
    } finally {
        store.dispatch('app/set_app_ready_status', true)
    }
})

export const EVENTS = {
    ON_SELECT_NODE: 'onSelectNode',
    ON_MOVE_NODE: 'onMoveNode',
}

export const LAYOUT_TYPE = { DEVICES: 'devices', ZONES: 'zones' }

export const METERING_LAYOUT_NAME = 'METERING_LAYOUT'
export const ONE_LINE_DIAGRAM_NAME = 'ONE_LINE_DIAGRAM'

export const SHAPES_COLORS = {
    RED: [244, 67, 54],
    PINK: [233, 30, 99],
    PURPLE: [156, 39, 176],
    INDIGO: [63, 81, 181],
    BLUE: [33, 150, 243],
    LIGHT_BLUE: [3, 169, 244],
    CYAN: [0, 188, 212],
    TEAL: [0, 150, 136],
    GREEN: [76, 175, 80],
    LIME: [205, 220, 57],
    YELLOW: [255, 235, 59],
    AMBER: [255, 193, 7],
}

// default shape values
export const DEFAULT_COLOR = SHAPES_COLORS.CYAN
export const DEFAULT_POSITION = { x: 0, y: 0 }
export const DEFAULT_SIZE = { width: 100, height: 100 }
export const RESIZE_STEP = 10
export const MIN_SIZE = 20

//style customizations
export const BACKGROUND_COLOR = 255
export const SHADOW_DISTANCE = 5
export const COLOR_SHADOW = [0, 0, 0, 50]
export const TRANSPARENCY = 255 * 0.4
export const TRANSPARENCY_MOUSE_OVER = 255 * 0.6
export const TRANSPARENCY_SELECTED = 255 * 1
export const SHAPES_BORDER_WEIGHT = 3

export const CANVAS_WIDTH = 2000
export const CANVAS_HEIGHT = 1400
export const ZOOM_MIN = 1
export const ZOOM_MAX = 10
export const ZOOM_DEFAULT = 8

export const SHAPES = {
    SQUARE: 'square',
    RECTANGLE: 'rectangle',
    CIRCLE: 'circle',
}
export const DEFAULT_SHAPE = SHAPES.SQUARE

export const SHAPES_ICONS = {
    [SHAPES.SQUARE]: 'mdi-square',
    [SHAPES.RECTANGLE]: 'mdi-rectangle',
    [SHAPES.CIRCLE]: 'mdi-circle',
}

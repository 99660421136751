// Zone icons
export const ZONE_ICON = 'mdi-hub'

// Modes icons
export const AUTO_MODE_ICON = 'mdi-auto-fix'
export const MANUAL_MODE_ICON = 'mdi-hand'

// Control icons
export const CONTROL_ICON = 'mdi-remote'
export const MAX_LEVEL_COMMAND_ICON = 'mdi-toggle-switch-variant'
export const MIN_LEVEL_COMMAND_ICON = 'mdi-toggle-switch-variant-off'
export const DISTANCE_COMMAND_ICON = 'mdi-signal-variant'
export const INTERVAL_TIME_COMMAND_ICON = 'mdi-timer'
export const LEVEL_0_COMMAND_ICON = 'mdi-lightbulb-outline'
export const LEVEL_1_COMMAND_ICON = 'mdi-lightbulb-on-50'
export const LEVEL_2_COMMAND_ICON = 'mdi-lightbulb-on-70'
export const LEVEL_3_COMMAND_ICON = 'mdi-lightbulb-on'

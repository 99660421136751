import { apiWithProject } from './greenetworkAPI'

const END_POINT = '/layouts'

const list = () => apiWithProject.get(`${END_POINT}`)

const get = (id) => apiWithProject.get(`${END_POINT}/${id}`)

const add = (layout) => apiWithProject.post(`${END_POINT}`, layout)

const update = (id, update) => apiWithProject.put(`${END_POINT}/${id}`, update)

const deletion = (id) => apiWithProject.delete(`${END_POINT}/${id}`)

const addNode = (id, node) =>
    apiWithProject.post(`${END_POINT}/${id}/nodes`, node)

const updateNode = (id, nodeId, node) =>
    apiWithProject.put(`${END_POINT}/${id}/nodes/${nodeId}`, node)

const Layouts = { list, get, add, update, deletion, addNode, updateNode }

export default Layouts

import axios from 'axios'
import store from '../store/index'

export const greenetworkBaseAPI = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 15000,
})

export const apiWithProject = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/v1/projects/<project_eui>`,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 15000,
})

const projectEuiUrl = (config) => {
    const { baseURL } = config
    const { project } = store.state
    const newBaseURL = baseURL.replace('<project_eui>', project.nameEui)
    config.baseURL = newBaseURL
    return config
}

const greenetworkAuth = (config) => {
    const { apiClientToken } = store.state.user
    config.headers.Authorization = `Bearer ${apiClientToken}`
    return config
}

const responseInterceptor = (response) => {
    const OK_CODE = 200
    if (
        response.status == OK_CODE ||
        response.status == 201 ||
        response.status == 204
    )
        return {
            status: response.status,
            data: response.data.data,
        }

    return Promise.reject(response)
}

greenetworkBaseAPI.interceptors.request.use(greenetworkAuth)
greenetworkBaseAPI.interceptors.response.use(responseInterceptor)

apiWithProject.interceptors.request.use(greenetworkAuth)
apiWithProject.interceptors.response.use(responseInterceptor)
apiWithProject.interceptors.request.use(projectEuiUrl)

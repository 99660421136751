<template>
  <div>
    <v-dialog v-model="open" persistent width="400px">
      <v-card>
        <v-card-title>
          <v-row justify="space-between" no-gutters>
            <v-col> Add new gateway </v-col>
            <v-col cols="auto">
              <v-btn icon @click="closeDialog()" :disabled="loadingSubmit">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field label="Gateway ID" required v-model="gateway.id" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Gateway key"
                required
                v-model="gateway.key"
              />
            </v-col>
            <v-col cols="12" v-if="errorMessage !== ''">
              <p class="red--text text-center shake mb-2 font-weight-medium">
                {{ errorMessage }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="end">
            <v-col cols="auto">
              <v-btn
                color="primary"
                :disabled="gateway.id === '' || gateway.key === ''"
                :loading="loadingSubmit"
                @click="addNewGateway(gateway)"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                color="blue-grey"
                @click="closeDialog()"
                :disabled="loadingSubmit"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :timeout="3000" class="pr-0">
      Gateway added successfully
      <v-btn color="pink" text @click="showSnackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { analytics } from "@/plugins/firebase";
export default {
  name: "AddGateway",
  props: {
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      showSnackbar: false,
      errorMessage: "",
      loadingSubmit: false,
      gateway: {
        id: "",
        key: "",
      },
    };
  },
  methods: {
    ...mapActions({ addGateway: "gateways/addGateway" }),
    async addNewGateway(gateway) {
      this.errorMessage = "";
      this.loadingSubmit = true;
      try {
        await this.addGateway({
          key: gateway.key,
          id: gateway.id,
          name: gateway.id,
        });
        this.closeDialog();
        this.showSnackbar = true;
        analytics.logEvent(`add_new_gateway`, { message: "Success" })
      } catch (error) {
        this.errorMessage =
          error.response.data.data ||
          "Gateway could not be added, check your data and try again later.";
        analytics.logEvent(`add_new_gateway`, { message: "Error" })
      } finally {
        this.loadingSubmit = false;
      }
    },
    closeDialog() {
      this.errorMessage = "";
      this.loadingSubmit = false;
      this.gateway.id = "";
      this.gateway.key = "";
      this.$emit("close");
    },
  },
};
</script>

import { Layouts } from '@/api'
import getBase64Image from '@/utils/getBase64Image'
import { METERING_LAYOUT_NAME, ONE_LINE_DIAGRAM_NAME } from '@/constants/layout'


const initialState = () => {
    return {
        list: [],
    }
}

const state = initialState() 

const getters = {
    getList(state) {
        return state.list
    },
    getById: (state) => async (id) => {
        const layout = state.list.find((layout) => layout.id === id)
        if (!layout?.image?.url) return layout

        layout.image.data = await getBase64Image(layout.image.url)
        return state.list.find((layout) => layout.id === id)
    },
    getByNameEui: (state) => async (name) => {
        const layout = state.list.find((layout) => layout.name_eui === name)
        if (!layout?.image?.url) return layout

        layout.image.data = await getBase64Image(layout.image.url)
        return layout
    },
}

const actions = {
    async fetchList({ commit }) {
        const response = await Layouts.list()
        const layouts = response.data.map((layout) => {
            const id = layout._id
            delete layout._id

            return { id, ...layout }
        })
        commit('SET_LIST', layouts)
    },
    async addLayout({ commit }, layout) {
        const response = await Layouts.add(layout)
        const id = response.data._id
        delete response.data._id

        commit('ADD_LAYOUT', { id, ...response.data, nodes: [] })
        return { id, ...response.data, nodes: [] }
    },
    async updateLayout({ commit }, layout) {
        const response = await Layouts.update(layout.id, layout.update)
        const id = response.data._id
        delete response.data._id

        commit('UPDATE_LAYOUT', { id, ...response.data })
        return response.data
    },
    async deleteLayout({ commit }, layoutId) {
        await Layouts.deletion(layoutId)
        commit('DELETE_LAYOUT', layoutId)
    },
    async addNodeLayout({ commit }, { layoutId, node }) {
        const { ref, name, x, y, width, height, shape, color } = node

        const response = await Layouts.addNode(layoutId, {
            ref,
            name,
            x,
            y,
            width,
            height,
            attributes: { shape, color },
        })

        commit('ADD_NODE_LAYOUT', { id: layoutId, node: response.data })
        return response.data
    },
    async updateNodeLayout({ commit, state }, { layoutId, nodeId, update }) {
        let updateBody = { ...update }
        if (updateBody.attributes) {
            const layout = state.list.find((layout) => layout.id === layoutId)
            const node = layout.nodes.find((node) => node.id === nodeId)
            updateBody.attributes = {
                ...node.attributes,
                ...updateBody.attributes,
            }
        }

        const response = await Layouts.updateNode(layoutId, nodeId, updateBody)
        commit('UPDATE_NODE_LAYOUT', { layoutId, node: response.data })
        return response.data
    },
}

const mutations = {
    SET_LIST(state, list) {
        state.list = list
    },
    ADD_LAYOUT(state, layout) {
        state.list.push(layout)
    },
    UPDATE_LAYOUT(state, layout) {
        const index = state.list.findIndex((l) => l.id === layout.id)
        state.list.splice(index, 1, layout)
    },
    DELETE_LAYOUT(state, layoutId) {
        const index = state.list.findIndex((l) => l.id === layoutId)
        state.list.splice(index, 1)
    },
    ADD_NODE_LAYOUT(state, { id, node }) {
        const layout = state.list.findIndex((layout) => layout.id === id)
        const nodes = state.list[layout].nodes
        nodes.push(node)

        state.list[layout].nodes = nodes
    },
    UPDATE_NODE_LAYOUT(state, { layoutId, node }) {
        const layout = state.list.findIndex((layout) => layout.id === layoutId)
        const nodes = state.list[layout].nodes
        const index = nodes.findIndex((n) => n.id === node.id)

        nodes.splice(index, 1, node)
        state.list[layout].nodes = nodes
    },
    RESET(state) {
        Object.assign(state, initialState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

import { Zone, Gateway, Logger } from '../../api'
import { db, auth } from '../../plugins/firebase'

const initialState = () => {
    return {
        collection: '',
        zonesArray: [],
        defaultZoneID: 'Z00',
    }
}

export const zones = {
    namespaced: true,
    state: initialState(),
    actions: {
        async setCollection({ commit }) {
            if (!auth.currentUser) return
            const userID = auth.currentUser.uid
            commit('UPDATE_COLLECTION', userID)
        },
        async fetchZones({ state, commit, dispatch }) {
            try {
                const zonesAPI = await Zone.get()
                const zonesAPIFormatted = zonesAPI.data.map((zone) => {
                    const { zoneAlias: name, zoneId: id } = zone
                    const zoneFormatted = { name, id, nodes: [] }
                    return zoneFormatted
                })
                const dataSortedByZones = zonesAPIFormatted.sort(
                    (zoneA, zoneB) => (zoneA.name > zoneB.name ? 1 : -1)
                )
                const defaultZone = dataSortedByZones.find(
                    (zone) => zone.id === state.defaultZoneID
                )
                const reorderedZonesArray = dataSortedByZones.filter(
                    (zone) => zone.id !== state.defaultZoneID
                )
                reorderedZonesArray.unshift(defaultZone)
                const zonesWithNodes = await dispatch(
                    'nodes/fetchNodes',
                    [...reorderedZonesArray],
                    { root: true }
                )
                commit('SET_ZONES', zonesWithNodes)
            } catch (error) {
                console.log(error)
            }
        },
        async createZoneID({ state }) {
            let concatChars
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVW'
            const numbers = '0123456789'
            const zones = state.zonesArray
            const zonesIDsInUse = zones.map((zone) => zone.id.substr(1, 2))
            const zonesIDsReserved = ['00', 'XY']
            const zonesIDsToAvoid = zonesIDsInUse.concat(zonesIDsReserved)

            do {
                const charsArray = []
                for (let index = 0; index < 2; index++) {
                    const isLetter = Math.random() < 0.5
                    let charSelected = isLetter
                        ? letters.charAt(
                              Math.floor(Math.random() * letters.length)
                          )
                        : numbers.charAt(
                              Math.floor(Math.random() * numbers.length)
                          )
                    charsArray.push(charSelected)
                }
                concatChars = charsArray.join('')
            } while (zonesIDsToAvoid.includes(concatChars))

            return concatChars
        },
        async addZone({ commit, dispatch }, zoneInfo) {
            let zoneStatus = true
            try {
                const { zoneName } = zoneInfo
                const zoneId = await dispatch('createZoneID')
                const zoneObj = { name: zoneName, id: `Z${zoneId}` }
                await Zone.post(zoneObj)
                commit('ADD_ZONE', zoneObj)
                return { zoneStatus }
            } catch (error) {
                zoneStatus = false
                const errorMsg = error.message
                return { zoneStatus, errorMsg }
            }
        },
        async editZone({ commit }, zoneInfo) {
            let zoneStatus = true
            try {
                const { zoneName, zoneId } = zoneInfo
                const zoneParams = { id: zoneId, name: zoneName }
                await Zone.update(zoneParams)
                commit('EDIT_ZONE', zoneInfo)
                return { zoneStatus }
            } catch (error) {
                zoneStatus = false
                const errorMsg = error.message
                return { zoneStatus, errorMsg }
            }
        },
        async fetchMongoValidation({ state }, mongoStatus) {
            let mongoValidation
            const wait = (ms) =>
                new Promise((resolve) => setTimeout(resolve, ms))
            for (let index = 1; index <= 16; index++) {
                await wait(1000)
                mongoValidation = await Logger.getStatus(mongoStatus.data)
                if (!mongoValidation.data) continue
                if (mongoValidation.data) {
                    if (mongoValidation.data.status === 'RECEIVED') break
                }
            }
            return mongoValidation
        },
        async controlZones({ state, commit, dispatch, rootState }, zoneInfo) {
            try {
                const stateZones = state.zonesArray
                const { zones, config, gateway } = zoneInfo
                const control = config.A === 1

                let configFormatted = control
                    ? {
                          auto: config.A,
                          min: config.LL,
                          max: config.LH,
                          d: config.D,
                          it: config.T,
                      }
                    : { auto: config.A, il: config.LV }
            
                const user_id = rootState.user.data._id
                const mongoStatus = await Gateway.sendInstructions(
                    zones,
                    'zones',
                    config,
                    gateway,
                    user_id
                )

                const mongoValidation = await dispatch(
                    'fetchMongoValidation',
                    mongoStatus
                )

                if (mongoValidation.data.status !== 'RECEIVED')
                    return mongoValidation.data.status

                const zonesModified = zones.map((zone) => {
                    const zoneToIterate = stateZones.find(
                        (zoneToFind) => zoneToFind.id === zone
                    )
                    const { id, name, nodes } = zoneToIterate
                    const zoneObj = { id, name, nodes, config: configFormatted }
                    return zoneObj
                })

                commit('CONTROL_MULTIPLE_ZONES', zonesModified)
                return mongoValidation.data.status
            } catch (error) {
                console.log(error)
                return 'ERROR'
            }
        },
        async changeNodeZone({ rootState, state, dispatch, commit }, data) {
            try {
                const { nodes, newZone } = data
                const zonesArray = state.zonesArray
                const newZoneInfo = zonesArray.find(
                    (zone) => zone.name === newZone
                )
                
                const gateway = rootState.gateways.mainGateway
                const user_id = rootState.user.data._id
                const mongoStatus = await Gateway.zoneSet(
                    newZoneInfo.id,
                    nodes.map((node) => node.id),
                    gateway,
                    user_id
                )
                const mongoValidation = await dispatch(
                    'fetchMongoValidation',
                    mongoStatus
                )

                if (mongoValidation.data.status !== 'RECEIVED')
                    return mongoValidation.data.status

                commit('MOVE_NODES_TO_OTHER_ZONE', data)

                return mongoValidation.data.status
            } catch (error) {
                console.log(error)
                return 'ERROR'
            }
        },
        async deleteZones({ state, commit }, zoneInfo) {
            let deletedItem = true
            try {
                const { zoneIdx } = zoneInfo

                const zoneName = state.zonesArray[zoneIdx].name
                const zoneID = state.zonesArray[zoneIdx].id

                await Zone.deletion(zoneID)

                const schedulesCollection = `${state.collection}/schedules`
                const schedulesFB = await db
                    .collection(`${schedulesCollection}`)
                    .get()
                const schedulesMapped = schedulesFB.docs.map((schedule) =>
                    schedule.data()
                )

                const layoutsCollection = `${state.collection}/layouts`
                const layoutsFB = await db
                    .collection(`${layoutsCollection}`)
                    .get()
                const layoutsMapped = layoutsFB.docs.map((layout) =>
                    layout.data()
                )

                const firestoreTtransaction = db.runTransaction(async () => {
                    for (
                        let scheduleIndex = 0;
                        scheduleIndex < schedulesMapped.length;
                        scheduleIndex++
                    ) {
                        const scheduleObj = schedulesMapped[scheduleIndex]
                        const scheduleDoc = await db
                            .doc(
                                `${schedulesCollection}/${scheduleObj.schedule_id}`
                            )
                            .get()
                        const zoneToDelete = scheduleDoc
                            .data()
                            .zones.find((zone) => zone === zoneName)

                        if (zoneToDelete !== -1) {
                            const zonesArray = scheduleDoc.data().zones
                            const filteredZonesArray = zonesArray.filter(
                                (zone) => zone !== zoneToDelete
                            )
                            await db
                                .doc(
                                    `${schedulesCollection}/${scheduleObj.schedule_id}`
                                )
                                .update({ zones: filteredZonesArray })
                        }
                    }

                    for (
                        let layoutIndex = 0;
                        layoutIndex < layoutsMapped.length;
                        layoutIndex++
                    ) {
                        const layoutSelected = layoutsMapped[layoutIndex]
                        if (
                            !layoutSelected.zones ||
                            layoutSelected.zones.length === 0
                        )
                            continue
                        const zonesUpdated = layoutSelected.zones.filter(
                            (zn) => zn.name !== zoneName
                        )
                        await db
                            .doc(`${layoutsCollection}/${layoutSelected.id}`)
                            .update({ zones: zonesUpdated })
                    }
                })
                await firestoreTtransaction

                commit('DELETE_ZONE', zoneID)

                return { deletedItem }
            } catch (error) {
                deletedItem = false
                return { error, deletedItem }
            }
        },
    },
    mutations: {
        SET_ZONES(state, zones) {
            localStorage.setItem('zones', JSON.stringify(zones))
            state.zonesArray = JSON.parse(localStorage.getItem('zones'))
        },
        UPDATE_COLLECTION(state, path) {
            path
                ? (state.collection = `usersGreenweb/${path}`)
                : (state.collection = null)
        },
        ADD_ZONE(state, zoneToAdd) {
            const zonesArray = state.zonesArray
            const defaultZone = zonesArray.find(
                (zone) => zone.id === state.defaultZoneID
            )
            const filteredZonesArray = zonesArray.filter(
                (zone) => zone.id !== state.defaultZoneID
            )
            const { id, name } = zoneToAdd
            const zoneObj = { id, name, nodes: [] }
            filteredZonesArray.push(zoneObj)
            filteredZonesArray.sort((a, b) => (a.name > b.name ? 1 : -1))
            filteredZonesArray.unshift(defaultZone)
            localStorage.setItem('zones', JSON.stringify(filteredZonesArray))
            state.zonesArray = JSON.parse(localStorage.getItem('zones'))
        },
        EDIT_ZONE(state, zoneToEdit) {
            const { zoneName, zoneId } = zoneToEdit
            const zonesArray = state.zonesArray
            const defaultZone = zonesArray.find(
                (zone) => zone.id === state.defaultZoneID
            )
            const filteredZonesArray = zonesArray.filter(
                (zone) => zone.id !== state.defaultZoneID
            )
            const zoneToUpdateIndex = filteredZonesArray.findIndex(
                (zone) => zone.id === zoneId
            )
            filteredZonesArray[zoneToUpdateIndex].name = zoneName
            filteredZonesArray.sort((a, b) => (a.name > b.name ? 1 : -1))
            filteredZonesArray.unshift(defaultZone)
            localStorage.setItem('zones', JSON.stringify(filteredZonesArray))
            state.zonesArray = JSON.parse(localStorage.getItem('zones'))
        },
        CONTROL_MULTIPLE_ZONES(state, zones) {
            const zonesArray = JSON.parse(localStorage.getItem('zones'))
            zones.forEach((zoneToIterate) => {
                const zoneIndex = zonesArray.findIndex(
                    (zone) => zone.id === zoneToIterate.id
                )
                zonesArray[zoneIndex] = zoneToIterate
            })
            localStorage.setItem('zones', JSON.stringify(zonesArray))
            state.zonesArray = JSON.parse(localStorage.getItem('zones'))
        },
        MOVE_NODES_TO_OTHER_ZONE(state, data) {
            const { nodes, newZone, oldZone } = data
            const zonesArray = JSON.parse(localStorage.getItem('zones'))

            const oldZoneIndex = zonesArray.findIndex(
                (zone) => zone.name === oldZone
            )
            const newZoneIndex = zonesArray.findIndex(
                (zone) => zone.name === newZone
            )

            const unmovedNodes = zonesArray[oldZoneIndex].nodes
            const movedNodes = zonesArray[newZoneIndex].nodes
            const remainingNodes = []

            unmovedNodes.forEach((node) => {
                const { id } = node
                const nodeFound = nodes.find((node) => node.id === id)
                if (nodeFound)
                    movedNodes.push({
                        id,
                        zone_id: zonesArray[newZoneIndex].id,
                    })
                else
                    remainingNodes.push({
                        id,
                        zone_id: zonesArray[oldZoneIndex].id,
                    })
            })

            zonesArray[oldZoneIndex].nodes = remainingNodes
            zonesArray[newZoneIndex].nodes = movedNodes

            localStorage.setItem('zones', JSON.stringify(zonesArray))
            state.zonesArray = JSON.parse(localStorage.getItem('zones'))
        },
        DELETE_ZONE(state, zoneToDelete) {
            try {
                const zonesArray = JSON.parse(localStorage.getItem('zones'))
                const zoneToDeleteIndex = zonesArray.findIndex(
                    (zone) => zone.id === zoneToDelete
                )
                const zoneName = zonesArray[zoneToDeleteIndex].name

                const filteredZonesArray = zonesArray.filter(
                    (zone) => zone.id !== zoneToDelete
                )

                const schedulesArray = JSON.parse(
                    localStorage.getItem('schedules')
                )
                const layoutsArray = JSON.parse(localStorage.getItem('layouts'))

                schedulesArray.map((schedule) => {
                    if (schedule.zones || schedule.zones.length > 0) {
                        const zones = schedule.zones.filter(
                            (zone) => zone !== zoneName
                        )
                        schedule.zones = zones
                        return schedule
                    }
                })

                localStorage.setItem(
                    'schedules',
                    JSON.stringify(schedulesArray)
                )
                state.schedules = JSON.parse(localStorage.getItem('schedules'))

                layoutsArray.map((layout) => {
                    if (layout.zones || layout.zones.length > 0) {
                        const zones = layout.zones.filter(
                            (zone) => zone.name !== zoneName
                        )
                        layout.zones = zones
                        return layout
                    }
                })

                localStorage.setItem('layouts', JSON.stringify(layoutsArray))
                state.layouts = JSON.parse(localStorage.getItem('layouts'))

                localStorage.setItem(
                    'zones',
                    JSON.stringify(filteredZonesArray)
                )
                state.zonesArray = JSON.parse(localStorage.getItem('zones'))
            } catch (error) {
                console.log(error)
            }
        },
        RESET(state) {
            Object.assign(state, initialState())
        }
    },
}

import { apiWithProject } from './greenetworkAPI'
const END_POINT = '/gateways'

function getCommandsMessageStructure(params, type) {
  const motionSensor = {
    function: "MotionSensor",
    data: {
      motionSensorDistance: params.D,
      motionSensorWaitTime: params.T,
    },
  };

  const smartActuator = {
    function: "SmartActuator",
    data: params.A
      ? {
          maxLevelConfiguration: params.LH,
          minLevelConfiguration: params.LL,
          controlModeType: 1,
        }
      : {
          targetLightCommand: params.LV,
          controlModeType: 0,
        },
  }

  const commands = params.A
    ? [motionSensor, smartActuator]
    : [smartActuator];

  return type === "zones"
    ? [{ function: "CommandSender", data: { commands } }]
    : commands;
}

function getScheduleConfigurationMessage(params) {
    return [
        {
            function: 'ScheduleConfigurator',
            data: {
                id: params.ID,
                time: params.HR,
                days: params.WD,
                commands: params.A
                    ? [
                          {
                              function: 'SmartActuator',
                              data: {
                                  maxLevelConfiguration: params.LH,
                                  minLevelConfiguration: params.LL,
                                  controlModeType: 1,
                              },
                          },
                          {
                              function: 'MotionSensor',
                              data: {
                                  motionSensorDistance: params.D,
                                  motionSensorWaitTime: params.T,
                              },
                          },
                      ]
                    : [
                          {
                              function: 'SmartActuator',
                              data: {
                                  targetLightCommand: params.LV,
                                  controlModeType: 0,
                              },
                          },
                      ],
            },
            active: params.E,
            delete: false,
        },
    ]
}

const get = () => apiWithProject.get(`${END_POINT}`)

const post = (key, name_eui, name) =>
    apiWithProject.post(`${END_POINT}`, {
        key,
        name_eui,
        name,
    })

const assign = (name_eui) =>
    apiWithProject.post(`${END_POINT}/main/${name_eui}`)

const deletion = (name_eui) => apiWithProject.delete(`${END_POINT}/${name_eui}`)

const deleteMainGateway = () => apiWithProject.delete(`${END_POINT}/main`)

const sendInstructions = (components, componentType, params, gateway, user_id) =>  apiWithProject.post(`communication/downlink/${gateway}${componentType === 'zones' ? '/zones' : ''}`, {
  class: 'Neurolink',
  targets: components,
  targetType: componentType,
  user_id,
  messages: getCommandsMessageStructure(params, componentType),
  ack: true
}).then((res) => res)
  .catch((err) => { console.log(err); });

const sendScheduleConfiguration = (zones, params, gateway, user_id) =>
    apiWithProject
        .post(`communication/downlink/${gateway}/zones`, {
            targets: zones,
            class: 'Neurolink',
            targetType: 'zones',
            user_id,
            messages: getScheduleConfigurationMessage(params),
            ack: true,
        })
        .then((res) => res)
        .catch((err) => {
            console.log(err)
        })

const scheduleEnabling = (zones, scheduleID, enable, gateway, user_id) =>
    apiWithProject
        .post(`communication/downlink/${gateway}/zones`, {
            targets: zones,
            class: 'Neurolink',
            targetType: 'zones',
            user_id,
            messages: [
                {
                    function: 'ScheduleConfigurator',
                    data: {
                        id: scheduleID,
                    },
                    active: enable,
                },
            ],
            ack: true,
        })
        .then((res) => res)
        .catch((err) => {
            console.log(err)
        })

const scheduleDeletion = (zones, scheduleID, gateway, user_id) =>
    apiWithProject
        .post(`communication/downlink/${gateway}/zones`, {
            targets: zones,
            class: 'Neurolink',
            targetType: 'zones',
            user_id,
            messages: [
                {
                    function: 'ScheduleConfigurator',
                    data: {
                        id: scheduleID,
                    },
                    delete: true,
                },
            ],
            ack: true,
        })
        .then((res) => res)
        .catch((err) => {
            console.log(err)
        })

const zoneSet = (zone, nodes, gateway, user_id) =>
    apiWithProject
        .post(`communication/downlink/${gateway}/zones`, {
            targets: nodes,
            class: 'Neurolink',
            targetType: 'devices',
            user_id,
            messages: [
                {
                    function: 'ZoneNode',
                    data: {
                        zoneID: zone,
                    },
                },
            ],
            ack: true,
        })
        .then((res) => res)
        .catch((err) => {
            console.log(err)
        })

const Gateway = {
    get,
    post,
    assign,
    deletion,
    sendScheduleConfiguration,
    deleteMainGateway,
    zoneSet,
    scheduleDeletion,
    scheduleEnabling,
    sendInstructions,
}

export default Gateway

import { Commands, Logger } from '@/api'
import { nanoid } from 'nanoid'
import { auth } from '@/plugins/firebase'
import { COMMAND_STATUS, COMMAND_TYPE } from '@/constants/commands'

const CHECK_LOGS_INTERVAL_TIME = 1000 * 7
let updateLogStatusInterval = null

const initialState = () => {
    return {
        queued: [],
        success: [],
        failed: [],
    }
}

const state = initialState() 

const getters = {
    getQueue(state) {
        return state.queued
    },
    getSuccess(state) {
        return state.success
    },
    getFailed(state) {
        return state.failed
    },
}

const actions = {
    async initCommandsLogsModule({ dispatch }) {
        if (!auth.currentUser && !updateLogStatusInterval) return
        if (auth.currentUser && updateLogStatusInterval) {
            clearInterval(updateLogStatusInterval)
            updateLogStatusInterval = null
            return
        }

        if (updateLogStatusInterval) return
        updateLogStatusInterval = setInterval(() => {
            dispatch('updateLogStatus')
        }, CHECK_LOGS_INTERVAL_TIME)
    },
    async updateLogStatus({ state, commit, dispatch }) {
        if (state.queued.length === 0) return

        const commandsId = state.queued.map((command) => command.id)
        const sendRequests = commandsId.map((id) => Logger.getStatus(id))

        const responses = await Promise.all(sendRequests)
        responses.forEach(async (response) => {
            if (!response || !response.data) return
            const { status, commandId } = response.data
            if (status === COMMAND_STATUS.QUEUE) return

            if (
                status === COMMAND_STATUS.ERROR ||
                status === COMMAND_STATUS.INVALID ||
                status === COMMAND_STATUS.TIMEOUT
            ) {
                commit('CHANGE_TO_FAILED', commandId)
                await dispatch('onFailedCommand', {
                    id: commandId,
                    data: response.data.data,
                })
                return
            }

            if (
                status === COMMAND_STATUS.DELIVERED ||
                status === COMMAND_STATUS.RECEIVED
            ) {
                commit('CHANGE_TO_SUCCESS', commandId)
                await dispatch('onSuccessCommand', {
                    id: commandId,
                    data: response.data.data,
                })
                return
            }
        })
    },
    async onFailedCommand({ state }, { id, data }) {
        const command = state.failed.find((command) => command.id === id)
        command.listeners.forEach((listener) => listener(data))
    },
    async onSuccessCommand({ state, dispatch }, { id, data }) {
        const command = state.success.find((command) => command.id === id)
        if (command.type === COMMAND_TYPE.SCHEDULE) {
            await dispatch(
                'schedules/processScheduleCommand',
                { name: command.data.name, data },
                { root: true }
            )
        }
        command.listeners.forEach((listener) => listener(data))
    },
    addCommandListener({ state, commit }, { id, listener }) {
        const onSuccessCommand = state.success.find(
            (command) => command.id === id
        )
        if (onSuccessCommand) {
            listener(onSuccessCommand.data)
            return
        }

        commit('ADD_COMMAND_LISTENER', { id, listener })
    },
    async addControlCommand(
        { commit, rootState },
        { targets, commands, type }
    ) {
        try {
            const gateway = rootState.gateways.mainGateway
            const messages = type === "zones"
            ? [{ function: "CommandSender", data: { commands } }]
            : commands;
            const response = await Commands.sendControlCommands(
                rootState.user.data._id,
                gateway,
                targets,
                messages,
                type
            )

            commit('ADD_TO_QUEUE', {
                id: response.data,
                data: { id: response.data, targets, messages, type },
                type: COMMAND_TYPE.CONTROL,
                created: new Date(),
                listeners: [],
            })

            return response.data
        } catch (error) {
            const fakeId = nanoid(5)

            commit('ADD_TO_FAILED', {
                id: fakeId,
                data: {
                    fakeId,
                    targets,
                    commands,
                    type,
                },
                type: COMMAND_TYPE.CONTROL,
                created: new Date(),
                listeners: [],
            })

            return null
        }
    },
    async addConfigureScheduledCommand(
        { commit, rootState },
        { id, name, time, days, commands, zones, active = true, remove = false }
    ) {
        const commandId = id ? id : nanoid(5)
        const command = {
            id: commandId,
            time,
            days,
            commands,
        }

        try {
            const gateway = rootState.gateways.mainGateway
            const user_id = rootState.user.data._id
            const response = await Commands.sendZoneSchedule(
                gateway,
                zones,
                command,
                active,
                remove,
                user_id
            )

            commit('ADD_TO_QUEUE', {
                id: response.data,
                data: { id, name, time, days, commands, zones, active, remove },
                type: COMMAND_TYPE.SCHEDULE,
                created: new Date(),
                listeners: [],
            })

            return response.data
        } catch (error) {
            commit('ADD_TO_FAILED', {
                id: null,
                data: { id, name, time, days, commands, zones, active, remove },
                type: COMMAND_TYPE.SCHEDULE,
                created: new Date(),
                listeners: [],
            })

            return null
        }
    },
}

const mutations = {
    ADD_TO_QUEUE(state, command) {
        state.queued.push(command)
    },
    ADD_TO_FAILED(state, command) {
        state.failed.push(command)
    },
    ADD_COMMAND_LISTENER(state, { id, listener }) {
        const index = state.queued.findIndex((command) => command.id === id)
        if (index === -1) return

        state.queued[index].listeners.push(listener)
    },
    CHANGE_TO_FAILED(state, id) {
        const index = state.queued.findIndex((command) => command.id === id)
        if (index === -1) return

        state.failed.push(state.queued[index])
        state.queued.splice(index, 1)
    },
    CHANGE_TO_SUCCESS(state, id) {
        const index = state.queued.findIndex((command) => command.id === id)
        if (index === -1) return

        state.success.push(state.queued[index])
        state.queued.splice(index, 1)
    },
    RESET(state) {
        Object.assign(state, initialState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

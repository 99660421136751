import Vue from 'vue'
import Vuex from 'vuex'
import { instructions } from '@/store/modules/instructions'
import { nodes } from '@/store/modules/nodes'
import { signup } from '@/store/modules/signup'
import authentication from './modules/authentication'
import { alert } from './modules/alert'
import { app } from './modules/app'
import { messages } from './modules/messages'
import { zones } from './modules/zones'
import { gateways } from './modules/gateways'
import layouts from './modules/layouts'
import user from './modules/user'
import project from './modules/project'
import schedules from './modules/schedules'
import commands from './modules/commands'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        alert,
        authentication,
        app,
        messages,
        instructions,
        nodes,
        signup,
        zones,
        gateways,
        layouts,
        project,
        schedules,
        commands,
    },
    actions: {
        resetStore({ commit }) {
            commit('alert/RESET')
            commit('authentication/RESET')
            commit('commands/RESET')
            commit('gateways/RESET')
            commit('instructions/RESET')
            commit('layouts/RESET')
            commit('messages/RESET')
            commit('nodes/RESET')
            commit('schedules/RESET')
            commit('zones/RESET')
        }
    }
})

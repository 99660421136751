import { User } from '../../api'
import { auth } from 'firebase'

const state = {
    data: null,
    client: null,
    projects: [],
    apiClientToken: null,
}

const getters = {
    getProjects(state) {
        return state.projects
    },
    getClient(state) {
        return state.client
    },
}

const actions = {
    async setUserData({ commit }, data) {
        let userData
        if (data) userData = data
        else {
            const token = await auth().currentUser.getIdToken()
            userData = await User.login({
                loginType: User.LOGIN_TYPE.TOKEN,
                token,
            })
        }

        const { user, client, projects, apiClientToken } = userData.data
        if (!user || !client || !projects || !apiClientToken)
            throw new Error('The user data is not complete')

        commit('SET_USER', user)
        commit('SET_PROJECTS', projects)
        commit('SET_PROJECT', client)
        commit('SET_API_CLIENT_TOKEN', apiClientToken)

        return userData
    },
    cleanUserData({ commit }) {
        commit('SET_USER', null)
        commit('SET_PROJECTS', [])
        commit('SET_PROJECT', null)
        commit('SET_API_CLIENT_TOKEN', null)
    },
}

const mutations = {
    SET_USER(state, data) {
        state.data = data
    },
    SET_PROJECTS(state, projects) {
        state.projects = projects
    },
    SET_PROJECT(state, client) {
        state.client = client
    },
    SET_API_CLIENT_TOKEN(state, token) {
        state.apiClientToken = token
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

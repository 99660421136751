const initalState = () => {
  return {
      type: null,
      message: null
  }
}

export const alert = {
  namespaced: true,
  state: initalState(),
  getters: {
    get_message(state) {
      return state.message;
    },
    get_type(state) {
      return state.type;
    },
  },
  actions: {
    success({ commit }, message) {
      commit('SUCCESS', message);
    },
    error({ commit }, message) {
      commit('ERROR', message);
    },
    clear({ commit }, message) {
      commit('RESET');
    },
  },
  mutations: {
    SUCCESS(state, message) {
      state.type = 'success';
      state.message = message;
    },
    ERROR(state, message) {
      state.type = 'error';
      state.message = message;
    },
    RESET(state) {
      Object.assign(state, initalState());
    }
  },
};

import { apiWithProject, greenetworkBaseAPI } from './greenetworkAPI'

const get = (nameEui) => greenetworkBaseAPI.get(`/projects/${nameEui}`)

const updateAttributes = (name, data) =>
    apiWithProject.put(`/attributes`, {
        name,
        value: data,
    })

const Commands = { get, updateAttributes }

export default Commands

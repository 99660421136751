import { Zone } from '@/api'
import { SCHEDULE_NAMES_ATTRIBUTES } from '@/constants/schedules'

const initialState = () => {
    return {
        list: [],
    }
}

const state = initialState()

const getters = {
    getList(state) {
        return state.list
    },
}

const actions = {
    async fetchList({ commit, state, rootState }) {
        if (state.list.length !== 0) return

        const zones = await Zone.get()

        const schedulesContainer = {}
        zones.data.forEach((zone) => {
            if (!zone.schedules) return

            const schedulesId = Object.keys(zone.schedules)
            schedulesId.forEach((id) => {
                const { time, days, commands } = zone.schedules[id]
                if (!time || !days || !commands) return
                
                if (!schedulesContainer[id]) {
                    const days = zone.schedules[id].days.split(',')

                    schedulesContainer[id] = {
                        id: id,
                        ...zone.schedules[id],
                        days,
                        zones: [],
                    }
                }

                schedulesContainer[id].zones.push({
                    id: zone.zoneId,
                    name: zone.zoneAlias,
                })
            })
        })

        const { attributes } = rootState.project
        if (attributes && attributes[SCHEDULE_NAMES_ATTRIBUTES]) {
            attributes[SCHEDULE_NAMES_ATTRIBUTES].forEach((schedule) => {
                if (!schedulesContainer[schedule.id]) return
                schedulesContainer[schedule.id].name = schedule.name
            })
        }

        const schedules = Object.values(schedulesContainer)
        commit('SET_LIST', schedules)
    },
    async processScheduleCommand({ commit, state, rootState }, { data, name }) {
        const message = data.messages[0]
        if (message.delete) {
            commit('DELETE_SCHEDULE', message.data.id)
            return
        }

        const scheduleExists = state.list.find(
            (schedule) => schedule.id === message.data.id
        )
        const mutation = scheduleExists ? 'EDIT_SCHEDULE' : 'ADD_SCHEDULE'

        // cambiar al obtener zonas de la api
        const zones = rootState.zones.zonesArray

        const scheduleZones = zones.filter((zone) =>
            data.targets.includes(zone.id)
        )
        data.targets = scheduleZones.map((zone) => ({
            id: zone.id,
            name: zone.name,
        }))

        commit(mutation, {
            id: message.data.id,
            name,
            time: message.data.time,
            days: message.data.days.split(','),
            commands: message.data.commands,
            active: message.active,
            zones: data.targets,
        })

        await this.dispatch('schedules/saveSchedulesCommandsNames')
    },
    async saveSchedulesCommandsNames({ state, dispatch }) {
        const names = state.list.map((schedule) => ({
            id: schedule.id,
            name: schedule.name,
        }))

        await dispatch(
            'project/updateAttributes',
            {
                name: SCHEDULE_NAMES_ATTRIBUTES,
                data: names,
            },
            { root: true }
        )
    },
}

const mutations = {
    SET_LIST(state, list) {
        state.list = list
    },
    ADD_SCHEDULE(state, schedule) {
        state.list.push(schedule)
    },
    DELETE_SCHEDULE(state, id) {
        const index = state.list.findIndex((schedule) => schedule.id === id)
        state.list.splice(index, 1)
    },
    EDIT_SCHEDULE(state, schedule) {
        const index = state.list.findIndex((item) => schedule.id === item.id)
        state.list.splice(index, 1, schedule)
    },
    RESET(state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

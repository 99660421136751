<template>
    <div>
        <v-dialog v-model="open" persistent width="500px">
            <v-card style="overflow: hidden;">
                <v-card-title>
                    Delete group
                </v-card-title>
                <v-row>
                  <v-col>
                      <p align="center" class="mt-4">
                          Are you sure you want to delete this group?
                      </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn 
                        @click="deleteItem()"
                        :loading="loading"
                        color="red" 
                      >
                        Delete
                      </v-btn>
                      <v-btn
                        :disabled="loading"  
                        color="blue-grey" 
                        @click="$emit('close', false)"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>    
                  </v-col>
                </v-row>
            </v-card>
        </v-dialog>
      <v-dialog v-model="msgSuccess" width="500px">
          <v-card :color="saveErr ? 'red' : 'primary'" class="text-center">
              <v-card-title class="white--text">
                {{ msgText }}
              </v-card-title>
          </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'DeleteGroup',
  props: {
    open: { type: Boolean, default: false },
    group: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapState({
        groups: state => state.nodes.groups,
    }),
  },
  data() {
    return {
      loading: false,
      msgSuccess: false,
      saveErr: false,
      msgText: ''
    }
  },
  methods: {
    ...mapActions({ 
      deleteGroup: 'nodes/deleteGroup', 
    }),
    async deleteItem(){
        try {
            this.loading = true
            let status = await this.deleteGroup(this.group)
            if (!status) throw new Error
            this.loading = false;
            this.saveErr = false
            this.msgText = 'Group deleted successfully'
            this.$emit('close', true)
            decodeURIComponent(this.$route.params.id) === this.group.name && this.$router.push({ name: 'home' })
            this.msgSuccess = true
            await this.sleep(3000).then(() => { this.msgSuccess = false; })
        } catch (error) {
            this.loading = false
            this.saveErr = true
            this.msgText = 'ERROR: Group could not be deleted'
            this.$emit('close', false)
            this.msgSuccess = true
            await this.sleep(3000).then(() => { this.msgSuccess = false; })
        }
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },    
  },
};
</script>

<style scoped>
.my-input input{
  text-transform: uppercase
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}
</style>

import { auth, db } from '../../plugins/firebase'
import { User } from '../../api'

const initialState = () => {
    return {
        status: {
            loggedIn: false,
            loggingIn: false,
        },
    }
}

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        logged_in(state) {
            return state.status.loggedIn
        },
        logging_in(state) {
            return state.status.loggingIn
        },
        get_user(state) {
            return state.user
        },
        get_user_id(state) {
            return state.user
                ? state.user.user_id
                    ? state.user.user_id
                    : state.user.email
                : null
        },
        get_token(state) {
            return state.user.token
        },
        get_status(state) {
            return state.status
        },
    },
    actions: {
        async login({ commit, dispatch }, payload) {
            const response = await User.login({
                email: payload.email,
                password: payload.password,
                loginType: User.LOGIN_TYPE.EMAIL,
            })

            if (response.status !== 200) throw new Error(response.data.data)

            const { user, client, projects, apiClientToken } = response.data
            if (!user || !client || !projects || !apiClientToken)
                throw new Error('The user data is not complete')

            await dispatch('user/setUserData', response, { root: true })

            await auth.signInWithCustomToken(response.data.firebaseCustomToken)
            commit('LOGIN_SUCCESS')

            return
        },
        async logout({ dispatch }) {
            localStorage.clear()
            sessionStorage.clear()
            await auth.signOut()
            dispatch('resetStore', null, { root: true });
        },
    },
    mutations: {
        LOGIN_SUCCESS(state) {
            state.status.loggedIn = true
        },
        LOGIN_FAILURE(state, payload) {
            state.user = undefined
            state.status.error = payload ?? {}
        },
        LOGGING_IN(state, payload) {
            state.status.loggingIn = payload
        },
        SET_PROJECTS(state, projects) {
            state.project = projects
        },
        SET_CLIENT(state, client) {
            state.client = client
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_API_TOKEN(state, token) {
            state.user.token = token
        },
        RESET(state) {
            Object.assign(state, initialState())
        }
    },
}

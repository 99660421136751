import axios from 'axios'
import { authHeader, handle_response } from './helper'
import { HOUR_PERIOD, LOCAL_OFFSET } from '@/constants/schedules'

export function format24Hour(time, period) {
    const [hours, minutes] = time.split(':').map((val) => parseInt(val))
    let localHour = hours

    if (period === HOUR_PERIOD.AM && localHour === 12) localHour = 0
    if (period === HOUR_PERIOD.PM && localHour < 12) localHour += 12
    localHour -= LOCAL_OFFSET

    if (localHour < 0) localHour += 24
    if (localHour >= 24) localHour -= 24

    return {
        hours: localHour.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: '00',
    }
}

export function format12Hour(time) {
    const [hours, minutes] = time.split(':').map((val) => parseInt(val))
    let localHour = hours + LOCAL_OFFSET

    if (localHour < 0) localHour += 24
    if (localHour >= 24) localHour -= 24

    const period = localHour < 12 ? HOUR_PERIOD.AM : HOUR_PERIOD.PM
    localHour = localHour % 12 || 12
    return {
        hours: localHour.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        period,
    }
}

export const schedules_service = {
    get_schedules,
    get_schedule,
    create_schedule,
    update_schedule,
    delete_schedule,
}

const URL = process.env.VUE_APP_GENERAL_API_ENDPOINT

function get_schedules(options) {
    const request_options = {
        method: 'get',
        url: `${URL}schedules`,
        headers: authHeader(),
        params: options,
    }

    return axios(request_options).then((value) => handle_response(value))
}

function update_schedule(id, message, options = null) {
    const request_options = {
        method: 'put',
        url: `${URL}schedules/${id}`,
        params: options,
        data: message,
    }

    return axios(request_options).then((value) => handle_response(value))
}

function get_schedule(id) {
    const request_options = {
        method: 'get',
        url: `${URL}schedules/${id}`,
    }

    return axios(request_options).then((value) => handle_response(value))
}

function create_schedule(message, options = null) {
    const request_options = {
        method: 'post',
        url: `${URL}schedules`,
        data: message,
        params: options,
    }

    return axios(request_options).then((value) => handle_response(value))
}

function delete_schedule(id) {
    const request_options = {
        method: 'delete',
        url: `${URL}schedules/${id}`,
    }

    return axios(request_options).then((value) => handle_response(value))
}

import { apiWithProject } from './greenetworkAPI'
import { SCHEDULE_COMMAND_FUNCTION } from '@/constants/commands'

const DEVICES_CLASS = 'Neurolink'
const END_POINT = '/communication/downlink'

const sendControlCommands = async (user, gateway, targets, commands, type) => {
    let URL = `${END_POINT}/${gateway}`
    type === 'zones' && (URL += '/zones')
    const response = await apiWithProject.post(URL, {
        user_id: user,
        targets,
        class: DEVICES_CLASS,
        targetType: type,
        messages: commands,
    })
    return response
}

const sendZoneSchedule = (gateway, zones, message, active, remove, user_id) =>
    apiWithProject.post(`${END_POINT}/${gateway}`, {
        targets: zones,
        class: DEVICES_CLASS,
        targetType: 'zones',
        user_id,
        messages: [
            {
                function: SCHEDULE_COMMAND_FUNCTION,
                data: message,
                active,
                delete: remove,
            },
        ],
    })

const Commands = { sendZoneSchedule, sendControlCommands }

export default Commands

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

import '@/_variables.scss'

Vue.use(Vuetify)

const colors = JSON.parse(localStorage.getItem('colors'))

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: colors ? colors.primary : '#06D676',
                secondary: colors ? colors.secondary : '#023243',
                accent: '#023243',
                background: '#f0f2f4',
                danger: '#FC5919',
            },
            dark: {
                primary: '#2ca23a',
                secondary: '#0284a3',
                accent: '#02abe8',
                background: '#F0F2F4',
            },
        },
        options: {
            customProperties: true,
        },
        light: process.env.VUE_APP_THEME_COLOR === 'light',
    },
})

<template>
    <v-app-bar app color="secondary" dark clipped-left dense class="navbar">
        <v-img src="@/assets/logo.png" max-width="50" height="auto" />
        <p class="my-auto ml-3">Greenweb</p>

        <v-spacer></v-spacer>

        <v-menu v-model="menu" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar color="secondary">
                            <v-icon dark>mdi-account</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                user.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                                user.email
                            }}</v-list-item-subtitle>
                            <p style="font-size: 12px" class="mt-3">
                                Version 2.4.1
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item @click="openColorDialog = true">
                        <v-list-item-title
                            >Change Color Settings</v-list-item-title
                        >
                        <v-list-item-action>
                            <v-icon small>mdi-cog</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-title>Log Out</v-list-item-title>
                        <v-list-item-action>
                            <v-icon small>mdi-logout</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>

        <ColorDialog
            :openDialog="openColorDialog"
            @close="openColorDialog = false"
        />
    </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ColorDialog from './dialogs/ColorDialog.vue'

export default {
    name: 'Toolbar',
    components: { ColorDialog },
    computed: {
        ...mapState({
            user: (state) => state.user.data,
        }),
    },
    data() {
        return {
            menu: false,
            openColorDialog: false,
        }
    },
    methods: {
        ...mapActions({
            doLogout: 'authentication/logout',
        }),
        async logout() {
            await this.doLogout()
            this.$router.push({ name: 'login' })
        },
    },
}
</script>

<style scoped>
.navbar {
    z-index: 10000;
}
</style>

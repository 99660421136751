import * as icons from './icons'

export const COMMAND_TYPE = {
    SCHEDULE: 'SCHEDULE',
    CONTROL: 'CONTROL',
}

export const COMMAND_STATUS = {
    QUEUE: 'QUEUE',
    DELIVERED: 'DELIVERED',
    INVALID: 'INVALID',
    ERROR: 'ERROR',
    TIMEOUT: 'TIMEOUT',
    RECEIVED: 'RECEIVED',
}

export const SCHEDULE_COMMAND_FUNCTION = 'ScheduleConfigurator'

export const CONTROL_MODES = {
    AUTO: 'AUTO',
    MANUAL: 'MANUAL',
}

export const CONTROL_MODES_VALUES = {
    [CONTROL_MODES.AUTO]: 1,
    [CONTROL_MODES.MANUAL]: 0,
}

export const TARGETS_TYPES = {
    ZONE: 'zones',
    DEVICE: 'devices',
}

export const CONTROL_MODE_TYPE_FUNCTION = 'SmartActuator'
export const CONTROL_MODE_TYPE_ATTRIBUTE = 'controlModeType'

export const ATTRIBUTES = {
    MAX_LEVEL: 'maxLevelConfiguration',
    MIN_LEVEL: 'minLevelConfiguration',
    DISTANCE: 'motionSensorDistance',
    INTERVAL_TIME: 'motionSensorWaitTime',
    LEVEL: 'targetLightCommand',
}

export const ATTRIBUTES_OPTIONS = {
    [ATTRIBUTES.MAX_LEVEL]: {
        functionName: 'SmartActuator',
        icon: icons.MAX_LEVEL_COMMAND_ICON,
        mode: CONTROL_MODES.AUTO,
        values: {
            0: 'LEVEL 0',
            1: 'LEVEL 1',
            2: 'LEVEL 2',
            3: 'LEVEL 3',
        },
    },
    [ATTRIBUTES.MIN_LEVEL]: {
        functionName: 'SmartActuator',
        icon: icons.MIN_LEVEL_COMMAND_ICON,
        mode: CONTROL_MODES.AUTO,
        values: {
            0: 'LEVEL 0',
            1: 'LEVEL 1',
            2: 'LEVEL 2',
            3: 'LEVEL 3',
        },
    },
    [ATTRIBUTES.DISTANCE]: {
        functionName: 'MotionSensor',
        icon: icons.DISTANCE_COMMAND_ICON,
        mode: CONTROL_MODES.AUTO,
        values: {
            6: 'Minimum',
            5: '20%',
            4: '40%',
            3: '60%',
            2: '80%',
            1: 'Maximum',
        },
    },
    [ATTRIBUTES.INTERVAL_TIME]: {
        functionName: 'MotionSensor',
        label: 'Interval',
        icon: icons.INTERVAL_TIME_COMMAND_ICON,
        mode: CONTROL_MODES.AUTO,
        values: {
            2: '90 seconds',
            3: '5 minutes',
            4: '10 minutes',
            5: '15 minutes',
            6: '20 minutes',
            7: '25 minutes',
        },
    },
    [ATTRIBUTES.LEVEL]: {
        functionName: 'SmartActuator',
        mode: CONTROL_MODES.MANUAL,
        icon: {
            0: icons.LEVEL_0_COMMAND_ICON,
            1: icons.LEVEL_1_COMMAND_ICON,
            2: icons.LEVEL_2_COMMAND_ICON,
            3: icons.LEVEL_3_COMMAND_ICON,
        },
        values: {
            0: 'LEVEL 0',
            1: 'LEVEL 1',
            2: 'LEVEL 2',
            3: 'LEVEL 3',
        },
    },
}

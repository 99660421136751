import { apiWithProject } from "./greenetworkAPI";

const END_POINT = "/devices/summaries/electric-meters-measures";

const getSummaries = (id, type, year, month, day, hour) => {
  return apiWithProject.get(
    `${END_POINT}/${id}?type=${type}&year=${year}${
      month ? `&month=${month}` : ""
    }${day ? `&day=${day}` : ""}${hour ? `&hour=${hour}` : ""}`
  );
};

const getHourlySummaries = (id, since, until) => {
  return apiWithProject.get(
    `${END_POINT}/${id}/hourly?since=${since}&until=${until}`
  );
};

const Summaries = {
  getSummaries,
  getHourlySummaries,
};

export default Summaries;
